/*
 * @Author: your name
 * @Date: 2021-06-20 17:42:53
 * @LastEditTime: 2021-06-20 17:59:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \server\src\axios\request.js
 */
import axios from "axios";
// import qs from "qs";

// const formdataHeaders = {'Content-Type': 'multipart/form-data','enctype':'multipart/form-data'};
console.log(axios)
/****** 创建axios实例 ******/
const service = axios.create({
baseURL: process.env.BASE_URL, // api的base_url
timeout: 5000, // 请求超时时间
headers: {'content-type': 'application/x-www-form-urlencoded'},
});
/****** request拦截器==>对请求参数做处理 ******/
service.interceptors.request.use(
    
    config => {
        // 在发送请求之前做些什么(后期在这里加上token)
        // const token = store.state.token;
        // token && (config.headers.Authorization = token);  
        
        // if(config.url.indexOf("upload") !== -1 ){
        //     Object.assign(config.headers, formdataHeaders);
        // }

        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
    
);
// 响应拦截器
service.interceptors.response.use(
    // 请求成功
    (res) => {
        if(res.status === 200) {
            // if (res.data.code !== '0' && res.data.msg) {
            //     tip(res.data.msg);
            // }
       
           return Promise.resolve(res.data)
        } else {
           return Promise.reject(res)
        }
    },
    // 请求失败
    error => {
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在200的范围 
            // errorHandle(response.status, response.data.msg);
            return Promise.reject(response);
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            // store.commit('changeNetwork', false);
        }
    }
);

export default service;