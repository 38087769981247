/*
 * @Author: your name
 * @Date: 2021-06-05 18:44:33
 * @LastEditTime: 2021-06-12 16:38:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \server\src\main.js
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import './assets/style/main.less'
import './assets/style/reset.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import store from '@/store'
import '@wangeditor/editor/dist/css/style.css'
// import Vuex from 'vuex'
// import VueCookies from 'vue-cookies'

// Vue.use(hljs.vuePlugin);
createApp(App).use(store).use(router).use(ElementPlus,{ locale }).mount('#app')
