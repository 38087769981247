import request from '@/axios/request';
import qs from "qs";

export function addDevice(data) {
    console.log(data)
    return request({
      url: '/api/script/addDevice',
      method: 'post',
      data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
    });
}
export function getDevice(data) {
    return request({
      url: '/api/script/getDevice',
      method: 'post',
      data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
    });
}
export function getDeviceType(data) {
    return request({
      url: '/api/script/getDeviceType',
      method: 'post',
      data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
    });
}
export function updateDeviceData(data) {
    return request({
      url: '/api/script/updateDeviceData',
      method: 'post',
      data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
    });
}
export function updateScriptUserData(data) {
    return request({
      url: '/api/script/updateScriptUserData',
      method: 'post',
      data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
    });
}
export function deleteScript(data) {
  return request({
    url: '/api/script/deleteScript',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
export function deleteid(data) {
  return request({
    url: '/api/script/deleteid',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
export function addYsqs(data) {
  return request({
    url: '/api/script/addYsqs',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
export function getYsqs(data) {
  return request({
    url: '/api/script/getYsqs',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}

export function SelectProject() {
  return request({
    url: '/api/script/SelectProject',
    method: 'get'
  });
}
export function addProject(data) {
  console.log(data)
  return request({
    url: '/api/script/addProject',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
export function udProject(data) {
  return request({
    url: '/api/script/udProject',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
export function deleteProject(data) {
  return request({
    url: '/api/script/deleteProject',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
export function getUserData(data) {
  return request({
    url: '/api/script/getUserData',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
export function addfunctionconfig(data) {
  console.log(data)
  return request({
    url: '/api/script/addfunctionconfig',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
export function upfunctionconfig(data) {
  return request({
    url: '/api/script/upfunctionconfig',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
export function getfunctionconfig(data) {
  console.log(data)
  return request({
    url: '/api/script/getfunctionconfig',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
export function deletefunctionconfig(data) {
  return request({
    url: '/api/script/deletefunctionconfig',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}