<!--
 * @Author: your name
 * @Date: 2021-06-19 21:06:33
 * @LastEditTime: 2021-06-19 21:11:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \server\src\views\layout\index.vue
-->
<template>
   <router-view></router-view>
</template>

<script>

export default {
  name :'App',

  data() {
    
  },
};
</script>

