<template>
<el-aside width="200px" style="background-color: #545c64" v-show="isShow">
    <el-menu
      router
      :default-active="path"
      @select="handleSelect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-menu-item index="/index" ref="/index">
        <i class="el-icon-setting"></i>
        <template #title>首页</template>
      </el-menu-item>

      <div v-if="userDate.type === 0">
        <el-menu-item
          v-for="item in column"
          :key="item.id"
          :index="'/index/articleList?columnId=' + item.id"
          :ref="'/index/articleList?columnId=' + item.id"
        >
          <i class="el-icon-setting"></i>
          <template #title>{{ item.columnName }}</template>
        </el-menu-item>
      </div>

      <el-menu-item
        index="/index/column"
        ref="/index/column"
        v-if="userDate.type === 0"
      >
        <i class="el-icon-setting"></i>
        <template #title>栏目添加</template>
      </el-menu-item>
      <el-menu-item
        index="/index/lable"
        ref="/index/lable"
        v-if="userDate.type === 0"
      >
        <i class="el-icon-setting"></i>
        <template #title>标签</template>
      </el-menu-item>
      <el-menu-item
        index="/index/articleCreate"
        ref="/index/articleCreate"
        v-if="userDate.type === 0"
      >
        <i class="el-icon-setting"></i>
        <template #title>文章发布</template>
      </el-menu-item>
      <el-menu-item
        index="/index/config"
        ref="/index/config"
        v-if="userDate.type === 0"
      >
        <i class="el-icon-setting"></i>
        <template #title>个人配置</template>
      </el-menu-item>
      <el-menu-item
        index="/index/project"
        ref="/index/project"
        v-if="userDate.type === 0"
      >
        <i class="el-icon-setting"></i>
        <template #title>项目配置</template>
      </el-menu-item>
      <el-menu-item
        index="/index/functionconfig"
        ref="/index/functionconfig"
      >
        <i class="el-icon-setting"></i>
        <template #title>功能配置</template>
      </el-menu-item>
      <el-menu-item
        index="/index/roledata"
        ref="/index/roledata"
      >
        <i class="el-icon-setting"></i>
        <template #title>角色数据</template>
      </el-menu-item>
      <el-submenu index="1">
        <template #title><i class="el-icon-message"></i>梦幻数据</template>
        <el-menu-item-group>
          <template #title>请选择分类</template>
          <el-menu-item @click="addnum">增加设备</el-menu-item>
          <!-- <el-menu-item index="/index/mhuser" ref="/index/mhuser">全部账号</el-menu-item> -->
          <!-- <el-menu-item index="/index/createuser" ref="/index/createuser">导入账号</el-menu-item> -->
          <el-menu-item
            v-for="item in scriptColumn"
            :key="item.id"
            :index="'/index/scriptshow?id=' + item.id"
            :ref="'/index/scriptshow?id=' + item.id"
          >
            设备号 - {{ item.accountType }}</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </el-aside>
  
</template>

<script>
import { getAllColumn } from "@/api/column";
import { addDevice, getDevice } from "@/api/script";
export default {
  name: "NavBar",
  props:['navShow'],
  data() {
    return {
      column: [],
      scriptColumn: [],
      path: "",
      userDate: this.$store.state.loginName,
      isShow:document.body.clientWidth > 700 ? true : false
    };
  },
  watch:{
    '$store.state.deleteType':function(newVal, oldVal){
        // console.log('newVal'+newVal)
        // console.log('oldVal'+oldVal)
        if(newVal){
          const arr = this.scriptColumn;
          arr.splice(arr.findIndex(item => item.id === parseInt(newVal)), 1)
        }
    },
     'navShow':function(newVal, oldVal){
        // console.log('newVal'+newVal)
        // console.log('oldVal'+oldVal)
        this.isShow = newVal
        
    }
  },
  mounted() {
    this.init();
    //console.log(this.deleteType)
    console.log(this.navShow);
  },
  methods: {
    addnum() {
      this.deleteType = 2
      let accountType = null;

      let arrNew = this.scriptColumn.map((item) => {
        return item.accountType;
      });
      this.scriptColumn.map((item, index) => {
        if (JSON.stringify(arrNew).indexOf(index + 1) === -1) {
          accountType = index + 1;
        }
      });
      
      addDevice({
        username: this.$store.state.loginName.username,
        accountType: accountType ? accountType : this.scriptColumn.length + 1,
      }).then((res) => {
        if (res.status === 200) {
          this.$message.success({
            message: "增加成功！",
            type: "success",
          });
          this.getDeviceData();
        }
      });
    },
    handleSelect(index) {
      if (index) {
        if (
          JSON.stringify(this.$store.state.navTab).indexOf(
            this.$refs[index].$el.innerText
          ) === -1
        ) {
          this.$store.commit("setAddNavTab", {
            name: this.$refs[index].$el.innerText,
            path: index,
          });
        }
      }

      // console.log(this.$refs[index].$el.innerText)
      // console.log(index)
      // console.log(indexPath)
      // console.log(item)
      // console.log(routeResult)

      // console.log(key, keyPath);
    },
    init() {
      getAllColumn().then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.column = res.data;
        }
      });
      this.getDeviceData();
      if (!this.path) {
        this.path = this.$route.fullPath;
      }
    },
    getDeviceData() {
      getDevice({ userid: this.$store.state.loginName.username }).then(
        (res) => {
          console.log(res);
          if (res.status === 200) {
            this.scriptColumn = res.data;
          }
        }
      );
    },
  },
};
</script>

<style>
</style>