const state = {
    navTab:[
        {name: '首页', path: '/index'}
    ],
    config:'',
    qiniu:'',
    loginName:'',
    deleteType:null

}
export default state