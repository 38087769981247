<template>
  <el-tabs
    v-model="editableTabsValue"
    type="card"
    closable
    @tab-click="show"
    @tab-remove="removeTab"
  >
    <el-tab-pane
      v-for="item in editableTabs"
      :key="item.name"
      :label="item.name"
      :name="item.path"
    >
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  name: "NavTab",
  data() {
    return {
      editableTabsValue: "/index",
      editableTabs: this.$store.state.navTab,
      tabIndex: 1,
    };
  },
  watch: {
    // 监听地址栏变化
    $route(to) {
      this.editableTabsValue = to.fullPath;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.editableTabsValue = this.$route.fullPath;
    },
    show(item) {
      this.$router.push(item.props.name);
    },
    removeTab(name) {
      if (name !== "/index" && name !== this.editableTabsValue) {
        this.$store.commit("delNavTab", name);
      }
    },
  },
};
</script>

<style>
</style>