<!--
 * @Author: your name
 * @Date: 2021-06-19 21:06:33
 * @LastEditTime: 2021-06-19 21:11:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \server\src\views\layout\index.vue
-->
<template>
  <el-container class="max-height">
    <NavBar :navShow='navShow' />
    <el-container>
      <el-header style="line-height: 60px">
        <el-row>
          <el-col :span="6" style="margin-top:5px"><i  v-if="isShowM" @click="onClickMenu" :class="[navShow ? 'el-icon-caret-left' :  'el-icon-menu']" style="font-size:30px"></i> </el-col>
          <el-col :span="18" style="text-align: right; font-size: 12px">
            <el-dropdown>
              <i class="el-icon-setting" style="margin-right: 15px"></i>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="out">退出</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <span>{{ $store.state.loginName.username }}</span>
          </el-col>
        </el-row>
      </el-header>

      <el-main>
        <NavTab />
        <div class="max-main-h">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import NavBar from "./components/NavBar";
import NavTab from "./components/NavTab";
export default {
  name: "App",
  components: {
    NavBar,
    NavTab,
  },
  data() {
    return{
        isShowM:document.body.clientWidth < 700,
        navShow:document.body.clientWidth > 700 ? true : false
    };
  },
   mounted() {
    //this.init();
    //console.log(this.deleteType)
    //console.log(this.$store);
    //console.log()
  },
  methods: {
    onClickMenu(){
      this.navShow = !this.navShow
    },
    out() {
      this.$store.commit("setLonigName", "");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  padding-top: 0px;
}
.max-height {
  height: calc(100vh - 0px);
}
.max-main-h {
  height: calc(100vh - 140px);
  overflow: auto;
}
</style>




