/*
 * @Author: your name
 * @Date: 2021-06-06 12:44:02
 * @LastEditTime: 2021-06-15 21:21:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \server\src\router\index.js
 */
import { createRouter, createWebHashHistory } from 'vue-router';

import Main from "@/views/layout/main.vue"

const routerHistory = createWebHashHistory();

const router = createRouter({
    history: routerHistory,  // history
    routes:[
        {
            path: '/',
            name: 'Login',
            component: () => import('@/views/login/login.vue')
          },
          {
            path: '/index',
            name: 'index',
            component:Main,
            children:[
              {
                path: 'table',
                name: 'table',
                component: () => import('@/views/layout/components/table.vue')
              },
              {
                path: 'column',
                name: 'column',
                component: () => import('@/views/column/list.vue')
              },
              {
                path: 'lable',
                name: 'lable',
                component: () => import('@/views/lable/list.vue')
              }
              ,
              {
                path: 'articleCreate',
                name: 'articleCreate',
                component: () => import('@/views/article/create.vue')
              }
              ,
              {
                path: 'articleList',
                name: 'articleList',
                component: () => import('@/views/article/list.vue')
              },
              {
                path: 'script',
                name: 'script',
                component: () => import('@/views/script/list.vue')
              },
              {
                path: 'createuser',
                name: 'createuser',
                component: () => import('@/views/script/createuser.vue')
              },
              {
                path: 'mhuser',
                name: 'mhuser',
                component: () => import('@/views/script/mhuser.vue')
              },
              {
                path: 'scriptshow',
                name: 'scriptshow',
                component: () => import('@/views/script/show.vue')
              },
              {
                path: 'config',
                name: 'config',
                component: () => import('@/views/config/list.vue')
              },
              {
                path: 'project',
                name: 'project',
                component: () => import('@/views/script/project.vue')
              },
              {
                path: 'roledata',
                name: 'roledata',
                component: () => import('@/views/script/roledata.vue')
              },
              {
                path: 'functionconfig',
                name: 'functionconfig',
                component: () => import('@/views/script/functionconfig.vue')
              }
            ]
          },
         
       
    ]
});

console.log(router)
export default router;