const mutations = {
    setConfig: function (state, value) {
        state.config = value
    },
    setQiniu: function (state, value) {
        state.qiniu = value
    },
    setAddNavTab:function(state,value){
        state.navTab.push(value)
    },
    setLonigName:function(state,value){
        state.loginName=value
    },
    delNavTab:function(state,value){
        state.navTab.map((item,index)=>{
            if(item.path === value){
                state.navTab.splice(index,1)
            }
        })

    },
    deleteType:function(state,value){
       state.deleteType = value
    }
}
export default mutations